import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/photoshop-cc-new-features",
  "date": "2014-03-21",
  "title": "PHOTOSHOP CC NEW FEATURES",
  "author": "admin",
  "tags": ["design", "photoshop"],
  "featuredImage": "feature.jpg",
  "excerpt": "Adobe Creative Cloud’s Photoshop CC  is packed with new tools and features, as well as some key workflow enhancements."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The shift to Photoshop CC also brings some long-term benefits like never having software that’s out of date. Let's dive deep into Photoshop CC new features.`}</p>
    <h2>{`01  Combines Photoshop Standard and Photoshop Extended`}</h2>
    <p>{`Photoshop CC combines Photoshop Standard and Photoshop Extended. So all Photoshop Extended features, including 3D tools, are included in Photoshop CC.                                     `}</p>
    <p>{`Before Creative Cloud, two versions of Photoshop existed: Photoshop and Photoshop Extended. The latter included powerful image, video, analysis and 3D tools. With Creative Cloud and Photoshop CC you now get all of this in one version - so you don't have to pay any more to get all the exciting features of Photoshop Extended. It may seem a little, but if you want to build 3D into your workflow, it's a lot…`}</p>
    <h2>{`02 Integration with Behance`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/df27e251-019b-469d-97ad-c3f2da36ad76.png",
        "alt": "Behance"
      }}></img></p>
    <p>{`Photoshop CC offers integration with Behance, the online portfolio sharing website for creative professionals. This integration offers the ability to post directly into your Behance gallery from Photoshop.  Along with the integration mentioned above, Photoshop CC subscribers get free access to the Behance Pro-site.`}</p>
    <p>{`When you join Creative Cloud, you can quickly sign up to Behance and, of course, its ProSite personal portfolio tool - both are free within the CC subscription. The best part? If you want to showcase a WIP, final illustration or anything you've created in Photoshop on Behance, you can upload it directly from Photoshop itself. Just go to File`}{`>`}{`Share to Behance. If you haven't linked your Behance account, you'll receive step-by-step instructions the first time you try to share. Also, you can sync Behance with ProSite, meaning that a Creative Cloud membership provides a very simple way to publish new work to your portfolio site.`}</p>
    <h2>{`03 Camera Shake Reduction`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/Screenshot-Upsample.jpg",
        "alt": "Camera Shake Reduction"
      }}></img></p>
    <p>{`Photoshop CC introduces the sneak-peaked ‘deblur’ filter that’s been talked about since 2011, which is now revealed as: Camera Shake Reduction.`}</p>
    <p>{`We've spoken about Photoshop CC's camera shake reduction previously, but it is still a handy feature new Camera Shake Reduction feature is great for when you've got some shaky shots you need to save for a client mockup or website. Whether your blur was caused by slow shutter speed or a long focal length, Camera Shake Reduction analyses its trajectory and helps restore sharpness - all in an intuitive dialogue of course.`}</p>
    <h2>{`04 Advanced Healing Brush, Radial Gradient tool and the Upright tool`}</h2>
    <p>{`Camera Raw 8 will include the new features being showcased in the Photoshop Lightroom 5 public beta, ie Advanced Healing brush, Radial Gradient tool and the Upright tool.`}</p>
    <h2>{`05. Smart Sharpen`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/Step08b.jpg",
        "alt": "Smart Sharpen"
      }}></img></p>
    <p>{`The Smart Sharpen filter will save you so much time that you won't know what to do with it all!
In terms of the new features in Photoshop CC, one great addition is the Smart Sharpen filter. Found in the Filter`}{`>`}{`Sharpen menu, it enables you to quickly reduce noise and halo effects, using adaptive sharpening technology. You can, of course, dynamically preview the effect, as well as compare the result to Photoshop's legacy sharpening tool with the check of a box. You can use presets or save your own, using Amount, Radius and Reduce Noise sliders (amongst many other options) to get the required result.`}</p>
    <h2>{`06. Generator technology`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/9d3d8328-43cc-4d00-8c6b-28597ed55ad2.png",
        "alt": "Generator technology"
      }}></img></p>
    <p>{`The Photoshop 14.1 update contains a brilliant new feature - and technology - called Generator. This is a brilliant workflow addition and essentially enables you to quickly create image assets in real-time as you work. Simply add a file extension to the name of your layer or layer group, and Photoshop will automatically create a JPG, PNG or GIF from the contents of that layer. If you make a change to that layer, the file is immediately updated. You can even add scaling factors. It's a huge new feature.`}</p>
    <h2>{`07. Save to Cloud`}</h2>
    <p>{`Picture this: a client wants to see a work in progress but doesn't really understand file formats and the like. Now, using Photoshop CC you can save your files directly to Creative Cloud and then, using the desktop app or browser-based dialog, choose to share files. The client simply receives an email and can view the project in their browser. How's that for a quick and easy project update? What's more, saving files to the Cloud enables you to manage them across multiple machines - desktop, laptop and even mobile devices.`}</p>
    <h2>{`08 Intelligent upsampling`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/c8c0b223-86fd-4fea-b195-26ca951557ab.png",
        "alt": "Intelligent upsampling"
      }}></img></p>
    <p>{`Intelligent upsampling – in Photoshop CC you will be able to make big prints without compromising edge detail or introducing noise.`}</p>
    <p>{`Scaling up lo-res images is now simple (and plugin-free) with intelligent upsampling. You've been sent a low-res image by a client who wants it blown up to a huge size. What do you do? It used to be that you would have to resort to the likes of the (albeit excellent) plugin Genuine Fractals to upsample your images without a glaring loss of quality. Thankfully, in Photoshop CC you can now do this directly in the app. Combine this with being able to easily share files via Creative Cloud, and suddenly it's easy to show your client exactly what the low-res image will look like at the requested size.`}</p>
    <h2>{`09 Special subscriber deal of $9.99`}</h2>
    <p>{`Adobe is offering a special subscriber deal of $9.99 for Photoshop CS3 users and above – for the first year.`}</p>
    <h2>{`10 Constant updates`}</h2>
    <p>{`It looks there’ll be no Photoshop CS7, and Photoshop CC will evolve with constant updates to subscribers. What this means is that there is no longer any need to wait 18 months to two years for an upgrade!`}</p>
    <h2>{`11. Lightroom`}</h2>
    <p>{`A perpetual license of Lightroom will still be available as will Photoshop CS6.`}</p>
    <h2>{`12 No need to be connected to internet`}</h2>
    <p>{`You do not need to be connected to the internet to use Photoshop CC. This is a very common misconception. The Photoshop CC software is installed on your computer. However, Adobe will need to periodically check your subscription is current so you’ll need to log on once a month.`}</p>
    <h2>{`13  Multi-shape and path selection`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/Select-Multiple-Paths-1.jpg",
        "alt": "Multi-shape"
      }}></img></p>
    <p>{`The ability to select multiple paths, shapes and vector masks at once is, again, a simple yet very useful addition. Even in multilayered documents with lots of paths, you can easily target the path and any layer you want right on the canvas using a new Filter mode (rather like the Filter mode in the Layers panel introduced in CS6).`}</p>
    <h2>{`14 Conditional actions`}</h2>
    <p>{`Actions are an important part of any professional Photoshop user's workflow - and with Photoshop CC they have become much better. For processing files they are brilliant: you use if/then statements to determine image properties before applying your action. They are all based on the rules you set up, so you are in complete control.`}</p>
    <h2>{`15 Editable rounded rectangles`}</h2>
    <p>{` 
`}<img parentName="p" {...{
        "src": "/images/2014-03-21-photoshop-cc-new-features/383b8161-bd47-4b19-9ee2-ee3b63b0ce9d.png",
        "alt": "Editable rounded rectangles"
      }}></img></p>
    <p>{`The editable rounded rectangles feature is great for web designers.
For a web designer who wants to export shapes as CSS data for use in another Adobe app such as Muse, editable rounded rectangles can be a lifesaver. You can resize shapes, edit them and re-edit them - before or after they're created.`}</p>
    <h2>{`16 Better 3D tools`}</h2>
    <p>{`Photoshop CC brings with it some improved 3D tools. For one, the 3D Scene Panel has been enhanced to make it easier to switch between a 2D and 3D workflow (thanks to a more conventional naming scheme and options). Secondly, higher quality live previews help you achieve great looks in less time. Plus, you can easily create better glow effects, scene illumination, and lighting for bumps and textures. Remember that After Effects now comes with Cinema 4D Lite, making your Creative Cloud 3D workflow - particularly across motion projects - a real breeze.`}</p>
    <h2>{`17 CSS From Layers`}</h2>
    <p>{`Web designers rejoice: the new CSS From Layers feature enables you to generate CSS code for specific design elements, and simply copy and paste the code into Dreamweaver or Muse to get the results you want. Let's say you designed a rounded rectangle element on a layer. Just `}<strong parentName="p">{`Ctrl`}</strong>{`"/right-click a layer or layer group and choose Copy CSS from the Context menu. It's as easy as that, and a great way to quickly move from mockups in Photoshop to live code in Dreamweaver or Muse.`}</p>
    <h2>{`18 Colour import from web files`}</h2>
    <p>{`With this clever new feature you can import colour swatches directly from HTML, CSS or SVG files - which is great when you need to find inspiration for a colour scheme or require help to match an existing scheme. It's another solid example of the excellent workflow features of Photoshop and the rest of Creative Cloud - and undoubtedly one you'll use again and again.`}</p>
    <h2>{`19 Improved type styles`}</h2>
    <p>{`The improved styles functionality in CC makes it incredibly easy to quickly set typestyles and apply them across your Photoshop document, enabling you to maintain consistency and accuracy across your design projects.`}</p>
    <h2>{`20 Expanded Smart Object support`}</h2>
    <p>{`Being able to apply effects non-destructively, without actually affecting the pixels, is a huge bonus. It enables you to experiment with different effects without committing or saving a duplicate of the file. In Photoshop CC you can apply Blur Gallery and liquify effects: your original file will stay intact as you push, pull, pucker or bloat the image or video. You can edit or remove the effects at any time, even after saving your file.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      